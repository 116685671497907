/******************************************************************
Site Name: 
Author: 

Stylesheet: Desktop Stylsheet

This is the desktop size. It's larger than an iPad so it will only
be seen on the Desktop. 

******************************************************************/


.header {	

	.clearHeader {

        > .row {min-height: 170px;}

        #logo {
            width:248px; height:130px;
		    background-size: 248px 130px; 
            @include transition(all 0.3s ease-in-out);
        }
		
	}
	 
	.darkHeader {

        > .row {min-height:150px;}

        #logo {
            width: 187px; height: 100px;
		    background-size: 187px 100px;
            @include transition(all 0.3s ease-in-out);
        }

	}
	
	
}


#content {height:auto;}
