/******************************************************************
Site Name: 
Author: 

Stylesheet: Super Large Monitor Stylesheet

You can add some advanced styles here if you like. This kicks in
on larger screens.

******************************************************************/
h1, .h1, h2, .h2, h3, .h3, h4, .h4 {margin:0.5em 0;} 
p + h4 {padding-top:0.5em;}
h3, .h3 {}
.responsive .h3 {margin-bottom:0; }
h1, .h1 { font-size: 2.5em; }
h2, .h2 {
    font-size: 2em;
}

.topMenu .nav > li > a {font-size: 114%;}

 .bigger {font-size: 1.4em;}

/*********************
LAYOUT & GRID STYLES
*********************/
	
/*********************
HEADER STYLES
*********************/




/**************************
HERO - Home
***************************/
/*body.Home #hero {max-height:75vh;}*/
body:not(.Home) #hero {
   /*height:300px;*/
    overflow:hidden;
     .block > p img {
            min-width:100%;
            max-width:initial;
            height:auto;
       }
}


/*********************
NAVIGATION STYLES
*********************/

.header #accountMenu {
}

.topMenu {		
	.nav {		
		> li {
			padding: 0 1em;
		}
	}
}



/*********************
CONTENT STYLES
*********************/

#content #leftcol > div > .first {padding:0 2em;}


/*********************
BLOCK LAYOUT
**********************/	


.columnlayout {
	
	> div, li {
		width: calc(33% - 2em);
	}
    /*Top row needs border adding seperatley to prevent double borders*/
    > div:nth-child(4) , li:nth-child(4) {
	}
}


 #bottomBlocks {
     .block {margin-bottom:0em;}

 }

 /* LEFT BLOCKS - Mission statement*/
    #homeMission{
        .row {
            height: 60vh;
            display: flex;
            align-items: center;
        }
    }

 
 /*Full width slider */
ul.responsive3 {
	
	.slick-list {
		.slick-track {
			.slick-slide {
				
				img {
					
				}
			
			}
		}
	}
}

