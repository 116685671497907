/******************************************************************
Site Name:
Author:

Stylesheet: Tablet & Small Desktop Stylesheet Landscape

Needed to change the menu styling from mobile to desktop

******************************************************************/
.mobileOnly {display:none;}
.desktopOnly {display:inline-block;}


h1, .h1 { font-size: 2em;}
h2, .h2, #homeMission {font-size: 1.6em;}
 .bigger, h3, .h3 {font-size: 1.2em;}
.submenu a {font-size: 0.9em;}


#homeMission {font-weight: 700;}

.column-card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 2em;
    margin-top: 0em;
}

/*************************
Colours
*************************/

.white { color: $white; }


/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.Home #content #leftcol > div > div, body.fullWidth #leftcol > div > div{
	width: 97%;
    padding: 2em 0;
}

/* Reduce padding on some rows*/
#breadcrumbs .row {padding: 1em 0;}

/*Increase padding content some rows */

/* Restrict width on some rows and content areas that behave like rows*/

		
/*********************
SPRITE
*********************/
		

    #logo {
        width: 167px; height: 80px;
	    background-size: 167px 80px;
	}

/*********************
HEADER STYLES
*********************/

body.Home .header {
	/*height: 112.09px;*/
}

.header {

    .row {
        position: relative;
        min-height: 150px;
    }

    #logo {
        bottom:unset;
            left: 0%;
        @include center(false, true);
    }

    #accountMenu {
		right: 0%;
        min-height:none;
        @include center(false, false);
        text-align:right;
        > a { display:block; position:relative; transform:none; top:unset; left:unset; }
        > a:first-of-type { font-size: 1.2em; margin-bottom:0.25em; }
	}

    #mainMenu {
        display: inline-block;
        position: absolute;
        right:0px;
        bottom: 10%;
    }

    a span.desktopOnly {
        padding: 0.4em 1.4em;
        border-radius: 4px;
        line-height: 2;
        transition: all 0.5s ease-in-out;
        background-color: $color-primary;
        color: $white;
    }
}


/**************************
HERO - Home
***************************/

body.Home #hero .row {
    top: 45%;
    width: 60%;
    left: 40%;
}

body:not(.Home) #hero {

	.block {
       img {max-width:1200px;}
    }
}/*hero*/


/*********************
NAVIGATION STYLES
*********************/

/*#touchMenu*/
.menu-btn {display:none;}


.topMenu {
    text-align: right;
    margin-left: auto;
    margin-right: auto;
    float: right;
    clear: none;


    a.toggleMenu {
        display: none;
    }

    .nav {
        position: relative;
        width: auto;
        border: 0;
        display: inline;


        > li {
            display: inline-block;
            padding: 0 0.4em;

            > a {
                outline: none;

                &:after {
                    content: '';
                    display: block;
                    width: 0;
                    height: 3px;
                    background: $color-secondary;
                    transition: width .3s;
                }

                &:hover, &:focus {
                    background: transparent;

                    &:after {
                        width: 100%;
                        //transition: width .3s;
                    }
                }
            }
            /*Prominent contact link*/
            &:last-child {
                
                
            }
            /*turn some of the main nav items OFF for public*/
            &:first-child {
                display: none;
            }

       }

        li {
            position: relative;

            a {
                padding: 0.5em 0em;
                background: none;
                border-bottom: none;


                &.parent:before {
                    display: none;
                }
            }
            /* highlight current page */
            a.activerootmenulink {
                color: $color-secondary;

                &:after {
                    width: 100%;
                    //transition: width .3s;
                }
            }

            &:first-child {
            }

            &:last-child {
                a.activerootmenulink {
                    
                }
            }
            /*
			plan your menus and drop-downs wisely.
			*/
            ul,
            ul.sub-menu,
            ul.children {
                position: absolute;
                z-index: 9999;
                left: -9999px;
                border-top: 2px solid $color-primary;

                li {

                    a {
                        padding: 0.5em 0.5em;
                        display: block;
                        width: 280px;
                        border-top: none;
                        border-radius: 0;
                        margin-right: 0;


                        &:hover,
                        &:focus {
                            border-top: none;
                        }

                        &:link {
                        }
                    }

                    &:first-child {
                    }

                    &:last-child {

                        a {
                        }
                    }
                    /*
					if you need to go deeper, go nuts
					just remember deeper menus suck
					for usability.
					*/
                    ul {
                        border-top: none;
                    }
                }
            }
            /* showing sub-menus */
            &:hover ul {
            }
        }
        /* end .menu ul li */
    }
    /* end .nav */
} /* end .topMenu */

.nav > li.hover > ul { left: 0;}
.nav li li ul { left: -9999px; z-index: 99; position:absolute; }
.nav li li.hover ul { left: 100%; top: 0; z-index: 99; position:absolute; }


/*********************
BLOCK LAYOUT
**********************/

    .columnlayout {
        > div, li {
            width: 45%;

        }

        article.newsBlock {
            width: 33%;
        }

        &.services {
            margin-top:-300px;
            > div {
                width: calc(33% - 2em);
                padding: 1em 2em;
                > p:first-of-type {margin: 0 0;}
            }
        }
        &.two-columns {
            > div, li {
            width: 45%;
            gap: 20px;
        }
    }
}

    #gallery li {
        width: 25%;
    }
    
    #bottomBlocks.columnlayout {
        > div {
            width: 50%;     
            padding:3em 5em; 
          
            .wrap {max-width: 500px; padding-left: 4em; padding-top: 2em; }
        }
    
        > div:last-of-type {
        
            background: $color-primary;
            margin-top:-4em;
            color: $white;
        }
    }

    /*spacing needed for above bottomBlocks */
    .spacer {padding-top:4em }
    #footer {
        nav.last {text-align:right; margin: 1em 0;}
    }



    /*Full width slider */
    body.Home #hero {
        overflow: hidden;

        .slick-slider {
        }
    }

    ul.responsive3 {
        max-height: 70vh;

        .slick-list {

            .slick-track {

                .slick-slide {
                    /*height:500px;*/

                    &:before {
                        background-color: rgba(0, 0, 0, 0.25);
                    }

                    img {
                        /*height: 420px;*/
                    }

                    .slideshow_caption {
                        
                    }
                }
            }
        }

        .slick-slider .slick-active img {
            margin-left: 0;
        }

        .slick-prev, .slick-next {
            top: 50%;
        }

        .slick-next {
            right: 0px;
        }

        .slick-prev {
            left: 0;
        }

        .slick-slider .slick-track, .slick-slider .slick-list {
            -webkit-perspective: 1000px;
        }
    }



    .columnlayout.two-columns:nth-child(1) {
        margin-bottom: 5em;
    }
    

    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        height: 50px;
        width: 50px;
    }
    /*********************
Sticky CTA
*********************/

    #stickyCTA {
    }