/******************************************************************
Site Name:
Author:

Stylesheet: Base Mobile Stylesheet

Be light and don't over style since everything here will be
loaded by mobile devices. You want to keep it as minimal as
possible. This is called at the top of the main stylsheet
and will be used across all viewports.

******************************************************************/

/*********************
GENERAL STYLES
*********************/
body {
	font-family: $sans-serif;
	font-size: 97.5%;
	line-height: 1.6;
	color: $black;
    font-weight:400;
} 

.mobileOnly {display:inline-block;}
.desktopOnly {display:none;}

/********************
BODY CLASSES
style a page via class
********************/

.home {}    /* home page */


/*********************
Z_INDEX
*********************/
.cd-top { z-index: 9999;}
.header { z-index: 1100;}
#content { z-index: 1000;}
#hero { z-index: 700;}
ul.children {z-index: 800;}


/*********************
LAYOUT & GRID STYLES
*********************/

.row, body.Home #content #leftcol > div > div, .row-small, body.fullWidth #leftcol > div > div {
	margin: 0 auto;
	width: 92%;
	padding: 2em 0;
}

/* Turn off padding on some rows*/
.header .row { padding: 0 0;}


/*********************
LINK STYLES
*********************/

a {
	color: $link-color;

	/* removing text decoration from all headline links */
		text-decoration: underline;

	/* on hover */
	&:hover, &:focus {
		color: $link-hover;
	}

	/* on click */
	&:active {

	}

	/* mobile tap color */
	&:link {
		/*
		this highlights links on iPhones/iPads.
		so it basically works like the :hover selector
		for mobile devices.
		*/
		-webkit-tap-highlight-color : rgba( 0, 0, 0, 0.3 );
	}
}

.button, nav a, #header a, #bottomBlocks a{
    text-decoration: none;
}

/******************************************************************
H1, H2, H3, H4, H5 STYLES
******************************************************************/

h1, .h1, h2, .h2, h3, .h3, .siteName {
	font-family: $headings;
    font-display: fallback;
	a {
		text-decoration: none;
	}
}

.fal, h4, .h4 {font-weight:300;}

#accountMenu .h2 {font-family:$sans-serif; font-display: fallback; }

h1, .h1, h2, .h2, h3, .h3, h4, .h4 {line-height: 1.3;}
strong, h1, .h1, h2, .h2, h3, .h3, h4, .h4,  h5, .h5, h6, .h6, #accountMenu > a, .topMenu .nav > li > a, a.link {font-weight: 700;}

h1, .h1, h2, .h2{margin:0.35em 0;}
h3, .h3, h5, .h5, h6, .h6{margin:0.75em 0;}
#accountMenu .h2, #address .h2, .slideshow_caption .h1,  .slideshow_caption .h2,#pushMenu p  {margin:0em 0;}

h3, .h3, h4, .h4, #message p, .siteName strong, .topMenu .nav > li > a, thead, .slideHeading, button, .button, .button-primary {text-transform:uppercase;}

h3, .h3, h4, .h4, #message p {letter-spacing:1px;}

h1, .h1, .phone { font-size: 1.6em;}
h2, .h2, .slideDescription, .fa-ul li, .bigger, .topBlock > div p, .phone {font-size: 1.2em;}
h3, .h3, h4, .h4, h5, .h5, h6, .h6, #breadcrumbs .siteName strong, .accountMenu {font-size: 1em;}

#rightcol h4, #rightcol .h4 {
    position: relative;
    text-align: center;
    color: black;
    margin-bottom: 1.5em;
    
    &:before {
      content: '';
      position: absolute;
      width: 100%;
      height: 1px;
      top: 200%;
      left: 0;
      bottom: 0;
      right: 0;
      margin: auto;
      background-color: $color-secondary;
    }  
  
    &:after {
      content: '';
      position: absolute;
      width:20%;
      height: 3px;
      top:200%;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      background-color: $color-secondary;
      @include transition(all 0.2s ease-in-out);
    }
  
    &:hover {
      &:after {
        width: 100%;
      }
    }
  }

  .h2::after:not(h3, p), h2::after {
  content: url('/userfiles/Images/icon-stripe-blue.png');
  position: relative;
  display: block;
  padding-top: 10px;
  }

  /*.h2::after:not(h3, p), h2::after {
    content: "";
    background: url(/userfiles/Images/icon-stripe-blue.png) no-repeat;
    position: relative;
    display: block;
    padding-top: 20px;
    margin-top: 10px;
  }*/



  .column-card p span {
    padding-top: 1em;
  }


.responsive .h3:after {display:none; margin-bottom:0; }

.smtext, #breadcrumbs, thead, .respond-form label, .holdForm ul, #message p { font-size: 0.85em; }

a.link, a.button, a.column-link {
    i {
        display: inline-block;
        padding-left: 0.25em;
        -webkit-transition: transform 0.3s ease-out;
        -moz-transition: transform 0.3s ease-out;
        -ms-transition: transform 0.3s ease-out;
        -o-transition: transform 0.3s ease-out;
        transition: transform 0.3s ease-out;
    }
    &:hover i{
        -webkit-transform: translateX(4px);
            -moz-transform: translateX(4px);
            -ms-transform: translateX(4px);
            -o-transform: translateX(4px);
            transform: translateX(4px);
    }
}

/*************************
Colours
*************************/


.blue, h4, .h4,
.darkHeader #accountMenu, #accountMenu .h2, .darkHeader .phone, body.Home #content ul li::before, blockquote:before, 
body.Home .columnlayout h2 a:hover, .respond-form h2, .fa-li, .slideshow_caption a.button, a.button.secondary:hover {
    color: $color-primary;
}

h2, .h2, .yellow, h3, .h3, .topMenu .nav > li > a {
    color: $color-secondary;
}

.h3 white {
    color: $white;
}



.light-blue, .black, a.button, button,ul.responsive3 .h2.slideDescription,
body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd),
body.Apartment #leftcol > div:first-of-type, body.CurrentSpecials.fullWidth #content #leftcol > div:nth-child(odd) h2 a {
    color: $color-tertiary;
}

.light-blue-bg {
    background: $color-quaternary;
}
/*body.Home #content #leftcol > div:first-of-type,*/


.grey
{color: lighten($black, 40%); }

.white, #pushMenu .nav li a, .pushy a,
.topMenu .nav li ul li a, 
ul.responsive3 .slideshow_caption a,
.slick-slider button:before,
.responsive .h3,
.holdForm, .holdForm h2, .holdForm a.button,
#footer #facebook a,  #copyright, #footer, #footer a, .cd-top i, #cta a.button, .button.primary, a.button.primary, a.button:hover, a.button.secondary,  .button.tertiary,
#hero .slick-slider button:before, #hero .heading h1, #hero .heading .h1
{
    color: $white;
}



/*************************
Turn off printable items
*************************/	
	.printable {display: none;}
	

/*********************
SPRITE
*********************/
/*use modernizer script stop detect browser fallback for next gen images*/
/*.no-webp .box { color: red; }
.webp .box { color: green; }*/	
/*.no-js #logo, .no-webp #logo { background-image: url('../images/logo.png'); }
.webp #logo { background-image: url('../images/logo.webp'); }*/

    #logo {
		display: block;
		margin: 0 auto;
        background: transparent url('../images/logo.svg') no-repeat center top;
        background-position: center top;
        width: 155px; height: 80px;
        background-size: 155px 80px;
	}



/*********************
HEADER STYLES
*********************/

#message {
    background-color: $color-tertiary;
    padding: 0.5em 1em;
    @include transition(all 0.2s ease-in-out);
    > p {margin: 0 0;}
}

/* STICKY NAV - This is where the header is set to be sticky. 
We can also change colour and size here - effects all take place onScroll (waypoints triggers this) */

body:not(.Home) .clearHeader{border-bottom: 2px solid $color-primary;}
.header {	

	.clearHeader {
		position: relative;
		background-color: $white;
		@include transition(all 0.2s ease-in-out);
	}
	 
	.darkHeader {
		position: fixed;
		background-color: $white;
		box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.2);
		@include transition(all 0.2s ease-in-out);
        top: 0;
	}
	
}

/* position header elements as needed */

.header {
    position: relative;
    .row {min-height: 110px; position: relative;}
    
    .menu {width:100%;}

    .menu-btn, #logo, #accountMenu > a{
		position: absolute;
	}

    #accountMenu {
		/*position: relative;*/
        position: absolute;
        bottom: 55%;
        right: 100px;
        /*min-height: 55px;*/
        /*> a { @include center(false, true); }
        > a:first-of-type {
            left: 0%;
            font-size: 1em;
        }*/
	}
    
    .menu-btn {
		right: 0%;
        bottom:17%;
        text-decoration: none;
        i, span{ display:block; vertical-align:middle; font-weight: 300; text-align: center;}
	}
    .fa.fa-bars {
        padding-bottom: 0.1em; 
    }
    
    
    #logo {
		left: -6%;
        bottom:13%
	}

    #mainMenu {
        display:none;
    }

    
}



/***********************
HERO - HERO
************************/

#hero {
	position: relative;
    overflow: hidden;
}

body.Home #hero .row { 
    @include center(true, true); width: 85%;
        background-color: rgba(30,93,150,0.7);
        padding: 2.2em;
        border-radius: 4px;
}


body:not(.Home) #hero {
    position: relative;
    height:250px;
    height:30vh;

    p {margin: 0 0;}
    p img, .heading{ @include center(true, true); }
    p img {min-height: 250px; max-width: none;z-index:-1; display:block; width:100%!important; height:100%; object-fit:cover}



    .heading { padding:1em; width:90%; z-index:2; text-align:center; }

    &:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.25); z-index:0;}


}


	
/*********************
NAVIGATION STYLES
*********************/
#touchMenu,
.menu-btn {display:block;}

#mainMenu {
    display:none;
}

#pushMenu {
    ul:nth-of-type(2){
        > li:first-of-type{display:none;} /*hide home link in 2nd nav*/
    }
}

.topMenu, #pushMenu {
	/*list-style:none;
	float: right;
	clear: right;*/
		
		
		a.toggleMenu {
			width: 100%; 
			padding: 2% 4%;
			text-transform: uppercase;
			margin-bottom: 0;
				span {
					display: block; 
					width:auto; 
					float:right;
					padding-left: 0.5em;
				}
					
		}
		
		.nav {
			/*margin-top:0;
			position: absolute;
			width: 96%;
			z-index: 99999;*/
		
				li {
					position: relative;
					text-align: left;
					a {
						display: block;
						text-decoration: none;
						padding: 0.5em 0.5em;
						//background: darken($black, 10%);
						border-bottom: 1px solid rgba(130, 154, 193, 0.21);
				
						&:hover, &:focus {
							//background: darken($black, 5%);
						}
						&.parent:before {
							content: "\f107";
							font-family: "Font Awesome 5 Pro";
                            font-weight: 300;
							display: block;
							float: right;
							font-size: large;
							padding: 0 0.5em;
							//line-height: 1.2;
						}
					}
					&.hover {
						> a.parent:before {
							content: "\f106";									
						}
					}
			
					&:first-child {}
			
					&:last-child {}
			
					/* Sub-menu level 2 */
					ul,
					ul.sub-menu,
					ul.children {
						margin-top: 0;
						border-top: 0;
						display: none;
			
						li {
			
							a {
								padding-left: 1em;
								background: darken($color-primary, 5%);
								border-bottom: 1px solid rgba(130, 154, 193, 0.21);
											
								&:hover,
								&:focus {
									background: darken($color-tertiary, 0%);
								}			
								&:link {}
								
								
							}
			
							&:first-child {}
			
							&:last-child {}
							
							/* Sub-sub-menu level 3	*/
							ul {
													
								li {
					
									a {
										padding-left: 1.5em;												
										background: $color-tertiary;
										&:hover,
										&:focus {
											background: lighten($color-tertiary, 5%);
										}
									}
									
									/* Sub-sub-menu level 4	*/
									ul {
															
										li {
							
											a {
												padding-left: 2em;
												background: lighten($color-tertiary, 30%);
												
											}
							
										}
							
									}/* end level 4	*/
					
								}
					
							}/* end level 3	*/
			
						}
			
					}/* end level 2	*/
					
					/* Hover effects */
					&:hover ul {
						
					}
					
			
				} /* end .menu li */
			
			
		
		} /* end .nav */

        .nav > li.hover > ul { left: 0; display: block;}
        .nav li li.hover > ul { left: 100%; top: 0; display: block; }

}/* end .topMenu */



/*********************
CONTENT STYLES
*********************/
/* content area all inner pagesset different or alternate row colours here*/
/*body.fullWidth #content #leftcol > div:first-of-type,*/
body.Home #content #leftcol > div:nth-of-type(even):not(#div393, #div394),
#intro,
#breadcrumbs
 {background: $color-quaternary;}
/*body.Home #content #leftcol > div:first-of-type  {background: $white;}*/

/* content area all inner pages */
#content, #homeBlocks, #bottomBlocks  {position:relative; background:$white;}

img.image-border {
    border: 5px solid $white;

    /*&:hover  {
        border: 5px solid $color-primary;
    }*/

}

.columnlayout.two-columns:nth-child(1) {
    margin-bottom: 2em;
}


.fa-ul {
    padding-left: 60px;}
	
	
			p {}


			ul, ol, table, dl {}

			#leftcol ul{ 
                list-style:none; padding: 0 0 0 0 ; margin: 1em 0em 1.5em 0em;
                li:not(.slick-slide)  {
                    position: relative;
                    padding-left: 2em;
                    margin-bottom: 0.75em;
                
				    &:before {
                        content: "\f058";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        color: $color-primary;
                        position: absolute;
                        display:block;
                        top: 0;
                        left: 0;
                        font-size:1.25em;
                        line-height: 1.3;
				    }
                }

			}



			ol {

				li {}

			}

			blockquote {
				margin: 0 0 0 0;
				text-align: left;
								
			}
			
			blockquote {
				padding: 1em;
				p {
					padding-left: 2em;
					margin-top: 0;
				}
				h5 {
					margin: 0.4em 0;
				}
								
				&:before {
					content: "\f10d";
					font-family: "Font Awesome 5 Pro";
                    font-weight: 300;
					display: block;
					float:left;
					padding-right: 0.5em;
					line-height: 1.2;
									
				}
				
				a {
					text-decoration: none;
				}
				
				em{
					text-align: right;
					}

			}

			dl {}

			dt {}

			dd {
				margin-left: 0;
				font-size: 0.9em;
				color: #787878;
				margin-bottom: 1.5em;
			}

			img {
				/*margin: 0 0 1.5em 0;*/
				max-width: 100%;
				height: auto;
			}


	#breadcrumbs { 
		/*border-bottom: 1px dotted lighten($color-secondary, 0%);*/
				
		> div {
            /*text-align: center;*/
             div {
			    display: inline;
            }
		}
	}
	
	

/*********************************
 BLOCKS
*********************************/

.column-card {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    padding: 2em;
    margin-top: 1.5em;
}

.block {}

.columnlayout {
    display: -webkit-flex;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    height: 100%;
    padding-left: 0;
    justify-content: center;
    /*turn off basic li styling*/
    padding: 0 0 0em 0;
    gap: 1.5em;

    &.two-columns {
        gap: 20px;
        justify-content: space-around;
    }

    li:before {
        display: none;
    }

    li {
        margin-bottom: 0em;
        padding-left: 0em;
    }

    > div, li, article {
        display: inline-block;
        vertical-align: top;
        flex: 1 1 auto;
        flex-grow: 0;
        width: 100%;

        > div {
            width:auto;
        }

        article.newsBlock {
            width: 100%;
        }
    }
    &.services {
        > div {
            background: #fff;
            margin: 1em;
            flex-grow: 1;
            width: calc(100% - 2em);
            
            text-align: center;
            padding: 1em;
            box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
    
            > .wrap {
                display: -webkit-flex;
                display: flex;
                flex-direction: row; align-items: center;
                > p {width: 40%; text-align: left; flex-grow: 1; }
                > h2 { text-align: left; width: 60%;}
            }
        }
    }
}
        

    #bottomBlocks.columnlayout {
        gap: 0px;
        justify-content: unset;

        > div {
            width: 100%;
            padding: 2em;            
        }

        > div:first-of-type {
            background: url('/userfiles/Images/store-front-white.jpg') no-repeat center;
            background-size: cover;
        }
    }

    /* #gallery */
    #gallery li {
        padding: 0 0.25em;
    }
    /* RIGHT BLOCKS */
    #rightcol {
        .block {
            margin-bottom: 1em;

            .respond-form, #newsletter {
                form {
                    width: 100%;
                }
            }


            ol, ul {
                padding: 0em 0em 1em 0em;

                li {
                    list-style: none;
                    line-height: 1.6;
                    padding: 0.25em;
                    margin-bottom: 0.25em;
                    border-bottom: 1px dotted lighten($black, 30%);

                    a {
                        display: block;
                        text-decoration: none;
                    }

                    a:hover, a.activesibling {
                    }
                }

                li {
                    &:before {
                        content: "\f105";
                        font-family: "Font Awesome 5 Pro";
                        font-weight: 300;
                        display: block;
                        float: left;
                        font-size: large;
                        padding-right: 0.5em;
                        line-height: 1.2;
                    }
                }
            }
        }
        /* end .block */
    }
    /* end #rightcol */
    /*TOP BLOCKS INNER PAGES*/

    body:not(.Home) #topblocks {
        background-color: darken($white, 10%);

        div.block {
            max-height: 350px;
            position: relative;
            overflow: hidden;
            margin-bottom: 0;

            > p {
                margin: 0 0;
            }

            img {
                /*max-height: 500px; 
              height: 80vh;
              max-width: none;*/
                width: auto;
                height: auto;
            }

            .caption {
                @include center();
                /*background-color:rgba(255, 255, 255, 0.6);*/
                width: 95%;
                text-align: center;
                padding: 1em;
            }
        }
    }

/* CENTERED TEXT WITH OVERLAY HOVER EFFECT */

/*ALL Pages*/


#homeBlocks {

    .light-blue-bg {
        margin-top: 0.5em;
    }

    /*.columnlayout {
        justify-content: center;*/
    
        > div {
            width: 100%;
            padding: 0 0;
            margin: 0 0em 1em 0em;
    
            > p {
                margin: 0 0;
            }
    
            > p img {
                display: block;
            }
        }
    
        > div.fullWidth {
            width: 100%;
            margin-bottom: 2em;
    
            > p {
                margin: 0.5em 0;
            }
        }
    }



#homeBlocks2 {

    > div {
        display: inline-block;
        vertical-align: top;
        flex: 1 1 auto;
        flex-grow: 0;
        width: 100%;
        padding: 0 0em;

    }
}

.cText {
    position: relative;
    text-align: center;
    overflow: hidden;

    .holdText, .caption, &:after, .holdText h2, .holdText a.button {
        @include transition(all 0.2s ease-in-out);
    }

    .holdText, .caption, i.fal {
        z-index: 10;
    }

    &:after, a:after {
        z-index: 5;
    }

    p, p picture, p picture img {
        display: block;
        margin: 0 0;
        z-index:0;
        width: 100%;
    }

    &:after {
        display: block;
        content: "";
        background: #00314d;
        opacity: 0.35;
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
    }
    .holdText {
        display: block;
        left: 0;
        right: 0;
        margin: initial;
        @include center(false, true);
        padding: 10%;

        h2 {
            display: inline-block;
            opacity: 1;
            font-weight: 600;
            }

        a.button {
            display: none;
            line-height: 1.4;
            }
    }
    
    &:hover {
        cursor: pointer;

        h2 {
            display: none;
            opacity: 0;
        }

        .holdText a.button {
            display: inline-block;
        }

        &:after {
            opacity: 0.7;
        }
    }
}



    /******************************************************************
FORM STYLES
******************************************************************/
    /* show/hid fields on gift voucher form*/
    .hide {
    }


    .respond-form {
        margin: 0em auto;
        padding: 0%;
        min-width: 190px;
        background-color: transparent;
    }

    .respond-form, #address {
        /* not nested as not all forms contain a table */
        table {
            width: 100%
        }

        form {
            margin: 0 0;

            li {
                list-style-type: none;
                clear: both;
                margin-bottom: 0.7335em;

                label,
                small {
                    display: none;
                }
            }

            fieldset {
                border: 0;
                margin: 0 0;
                padding: 0 0 0 0;
            }

            input[type=text],
            input[type=email],
            input[type=date],
            input[type=url],
            input[type=tel],
            textarea,
            select {
                padding: 1em;
                background-color: $white;
                border: 1px solid $color-primary;
                margin: 0 0 1em 0;
                display: table-cell;
                width: 100%;
                max-width: 100%;
        

                &:focus {
                    /*background-color: #fff;*/
                }
                /* form validation - comment out as editor doesn't support some of the html5 validation techniques */
                /* &:invalid {
				outline: none;
				border-color: #fbc2c4;
				background-color: #f6e7eb;
				-webkit-box-shadow: none;
				   -moz-box-shadow: none;
				    -ms-box-shadow: none;
						box-shadow: none;
			}*/
            }

            input[type=checkbox] {
                margin-right: 5px;
            }

            input[name="180_LeaseEnquiry"] {
                margin-bottom: 1em;
            }


            .required {
                background-position: right top;
                background-repeat: no-repeat;
            }

            textarea {
                height: 80px;
            }

            img {
                float: left;

                &.fullwidth {
                    max-width: 100%;
                }
            }
            input[type="submit"] {
                border: 2px solid $white;
            }
        }
    }

    #address form {
        margin: 0 auto;

        input[type=email] {
            width: auto;
            padding: 0.5em;
        }

        input[type="submit"] {
            min-height: 36px;
        }
    }
    /*********************
LEFT COLUMN
*********************/
    #leftcol {
        padding-bottom: 0em;

        #div307 {
            div {
                text-align: center;
            }
        }
    }

    /*********************
FOOTER STYLES
*********************/


    #bottomBlocks {
        
        background:$color-quaternary;
    }

    #footer {
        

        background: $color-secondary;

        > div {


            i {
                padding-right: 0.25em;
            }

            #copyright {
                .first {
                    a {
                        display: block;
                    }
                }
            }

            nav {
                ul {
                    li {
                        border-bottom: 1px dotted $color-quaternary;

                        a {
                            display: block;
                            line-height: 2;
                        }
                    }
                }
            }

            #privacy {


                a {
                    padding: 0 0.5em;

                    &:first-child {
                        border-right: 1px solid $color-quaternary;
                    }
                }
            }
        }
    }

/* ================================================ */
/* reCAPTCHA */
/* ================================================ */

.grecaptcha-badge {
    z-index:9999;
}

    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    .rslides {
        position: relative;
        list-style: none;
        overflow: hidden;
        width: 100%;
        padding: 0;
        margin: 0;

        li {
            position: absolute;
            display: none;
            width: 100%;
            left: 0;
            top: 0;
            /*padding: 2%;*/
            background: $white;
        }

        img {
            display: block;
            height: auto;
            float: left;
            width: 100%;
            border: 0;
        }
    }

    .rslides_nav {
        display: none;
        visibility: hidden;
    }

    .slides1_nav {
        display: none;
        visibility: hidden;
    }

    #leftcol li.slick-slide:before,
    #leftcol ul.responsive .slick-dots li:before {
        display: none;
    }
    /* ================================================ */
    /* SLIDESHOWS */
    /* ================================================ */
    /*.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-perspective: 1000px;
}*/
    /* SLIDER */
    .slick-slider {
        padding: 0;
        list-style: none;

        .slick-list {
            margin: 0 auto;

            .slick-track {

                .slick-slide {
                    position: relative;
                    padding: 0;

                    .slideshow_caption {
                    }
                }
            }
        }

        button {
            padding: 0.2em;
            height: auto;
            z-index: 9999;

            &:before {
                content: "\f104";
                font-family: "Font Awesome 5 Pro";
                font-weight: 300;
                height: auto;
                -webkit-font-smoothing: antialiased;
                -moz-osx-font-smoothing: grayscale;
            }
        }

        .slick-next:before {
            content: "\f105";
            padding-left: 0.1em;
        }
    }

    .slick-prev, .slick-next {
        top: 48%;
        z-index: 9999;
    }

    .slick-prev {
        left: 1px;
    }

    .slick-next {
        right: 1px;
    }

    .slick-arrow:hover, .slick-arrow:focus, .slick-arrow:active {
        border: none;
    }

    .slick-slider .slick-list, .slick-track, .slick-slide, .slick-slide img {
        width: 100%;
    }

    .slick-dots li button:before, .slick-dots li.slick-active button:before {
        color: $white;
    }
    /*Full width slider x3 with captions, dots and arrows */

    ul.responsive {
        /*padding-bottom: 3em;*/
        overflow: visible;

        .slick-list {

            .slick-track {

                .slick-slide {

                    img {
                        @include transition(all 0.3s ease-in-out);
                    }

                    &:hover {
                        img {
                            @include transform($zoom);
                            @include transition(all 0.3s ease-in-out);
                        }
                    }

                    .slideshow_caption {
                        /*text-align: center;*/
                        width: 80%;
                        background-color: rgba(0, 0, 0, 0.7);
                        opacity: 1;
                        padding: 0.5em;
                        bottom: 0;
                        @include center(true, false);
                        @include transition(all 0.3s ease-in-out);
                    }
                }
            }
        }


        .slick-prev {
            left: 0
        }

        .slick-next {
            right: 0
        }

        .slick-dots {
            bottom: 0;

            li:before {
                display: none;
            }
        }
    }
    /*Full width slider with captions */
    ul.responsive3 {
        margin-bottom: 0;

        .slick-list {

            .slick-track {

                .slick-slide {
                    height: 420px;
                    height: 85vh;

                    &:before {content:""; position:absolute; display: block; width:100%; height:100%; background-color: rgba(0, 0, 0, 0.45); }
                    picture {position: relative; display:block; width:100%; height:100%;}

                    img {
                        display:block;
                        max-width: none;
                        width:auto;
                        position: relative;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%);
                        -webkit-transform: translate(-50%, -50%);
                        -ms-transform: translate(-50%, -50%);
                        transition: transform 3s;
                        min-width: 100%;
                        min-height: 100%;
                        z-index: -1;
                    }

                    p:last-of-type {
                        margin-bottom: 0.75em;
                    }

                    a.button:first-of-type {
                        animation-delay: 1s;
                    }

                    a.button {
                        animation-delay: 1.5s;
                    }
                }

                .slick-slide.slick-active {

                    img {
                        display:block;
                        top: 50%;
                        left: 50%;
                        transform: translate(-50%, -50%) scale(1.05);
                        -webkit-transform: translate(-50%, -50%) scale(1.05);
                        -ms-transform: translate(-50%, -50%) scale(1.05);
                        position: relative;
                        min-width: 100%;
                        min-height: 100%;
                    }
                }


                .slideshow_caption {opacity:0; visibility:hidden;display:none; @include transition(all 0.5s ease-in-out); position: relative;}
				.slideshow_caption.activate {background: rgba(255, 255, 255,0); opacity:1; visibility: visible;display:block; text-align: center; @include transition(all 0.5s ease-in-out);}
                .slideshow_caption p.h5 { top: -15%; @include center(true, false);}
            }
        }
    }
    /************************
RESPONSIVE VIDEO WRAPPER
************************/
    .video-container {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 35px;
        height: 0;
        overflow: hidden;

        iframe {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
        }
    }
    /*********************
CUSTOMISE colorbox
*********************/
    #cboxContent {
        padding: 0 40px
    }

    #cboxCurrent, #cboxNext, #cboxNext, #cboxPrevious, #cboxClose {
        top: 4px;
    }

    #cboxNext, #cboxPrevious {
        top: 50%;
    }

    #cboxPrevious {
        left: 0;
    }

    #cboxNext {
        right: 0;
    }

    #cboxTitle {
        font-size: 120%;
        line-height: 1.8em;
    }

    #cboxLoadedContent {
        margin-top: 28px;
    }
    /*************************
STICKY BACK TO TOP
*************************/
    .cd-top {
        display: inline-block;
        height: 40px;
        width: 40px;
        text-align: center;
        position: fixed;
        bottom: 3em;
        right: 0.5em;
        box-shadow: 0 0 10px rgba(0, 0, 0, 0.05);
        white-space: nowrap;
        background: darken($color-tertiary, 5%);
        visibility: hidden;
        opacity: 0;
        @include transition(opacity .3s 0s, visibility 0s .3s);
        border-radius: 50%;


        i {
            margin: auto;
            line-height: 2.5;
            @include center();
        }
    }

    .cd-top.cd-is-visible, .cd-top.cd-fade-out, .no-touch .cd-top:hover {
        @include transition(opacity .3s 0s, visibility 0s .3s);
    }

    .cd-top.cd-is-visible {
        /* the button becomes visible */
        visibility: visible;
        opacity: 1;
    }

    .cd-top.cd-fade-out {
        /* if the user keeps scrolling down, the button is out of focus and becomes less visible */
        opacity: .5;
    }

    .no-touch .cd-top:hover {
        background-color: #e86256;
        opacity: 1;
    }
    /* used for pagination on the news listing page*/
    div.new-items-pagination {
        display: grid;
        grid-template-columns: 50% 50%;
        align-items: center;
        background: lighten(#000000, 85%);
        margin: 1em auto;
        padding: 0;

        .news-item-label, .news-items-next {
            padding: 0.6em;
        }

        .news-items-next {
            text-align: right;
        }
    }

    /*********REVIEW Full & Limited Styles*********/
.gmb-review {
    background-color: $white;
    display: inline-block;
    margin: 0em 0em 1.5em 0;
    width: 100%;
    border: 1px solid #dddddd;
    box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.2);
    padding: 1.5em;
}

.gmb-header {
    font-weight: 700;
    color: #2196f3;

    br {
        display: none;
    }

    img {
        width: 25%;
        padding-right: 1em;
        float: left;
    }
}

.gmb-reviewdate {
    color: darken($color-quaternary, 25%);
}

.gmb-rating {
    padding-top: 0.5em;
    max-height: 55px;
}

/*********REVIEWS FULL*********/
.gmb-widget-full:not(.holdSlider) {
    column-count: 1;

    .gmb-rating {
        position: relative;

        &:before {
            content: url(../images/google-21.png);
            position: absolute;
            top: -80%;
            right: -2px;
            display: block;
        }
    }
}

/*********REVIEWS SLIDER*********/
.gmb-widget-full.holdSlider {
    width: 100%;
    .slick-slide {
        .gmb-review {
            background-color: transparent;
            border: none;
            box-shadow: none;
        }
    }
}


/*********REVIEWS LIMITED*********/
.gmb-limited {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    max-width: 1200px;
    margin: 0 auto;

    .gmb-review {
        width: 100%;
        flex: 1 1 auto;
        flex-grow: 0;
        text-align: center;
        position: relative;

        &:before {
            content: url(../images/google-21.png);
            font-weight: 900;
            position: absolute;
            top: 10%;
            right: 20px;
            display: block;
        }
    }

    .gmb-header {
        width: 100%;
        display: block;
        text-align: left;
        margin: 0 auto;

        img.gmb-avatar {
            width: 100%;
            padding: 0;
            max-width: 46px;
            margin-right: 0.5em;
        }
    }

    .gmb-reviewdate {
        text-align: left;
    }

    .gmb-rating {
        height: 30px;
        overflow: hidden;
        display: inline-block;
        text-align: left;
        width: 100%;
        padding-top: 0;
    }

    .gmb-reviewtext {
        text-align: left;
        color: $black;
    }
}

#div390 .sixcol.first {
    position: sticky;
    top: 30em;
}

table.ultrasound-guide {
    width: 100%!important;
}